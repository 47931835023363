<template>
  <div>

        <v-form v-model="valid">
          <v-container>
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
               <v-file-input 
                prepend-icon="mdi-movie-open-outline" 
                show-size outlined placeholder="DinVideoFil.mp4" 
                hint="klikk for å velge fil" chips 
                accept="video/mp4" Label="Velg video" 
                id="file" ref="file" v-model="file">
              </v-file-input>
               <span> Blir lagret med filnavn: {{fileName}}</span>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="title"
                  :counter="30"
                  label="Tittel"
                  required
                  @input="fileSlug"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="slug"
                  :counter="30"
                  label="Slug (Url-navn)"
                  required
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="posterTime"
                  label="Postertid"
                  required
                ></v-text-field>
              </v-col>

              <v-col class="d-flex" cols="12" md="4">
                <v-select
                  v-model="kategorivalg"
                  :items="categories"
                  item-text="name"
                  item-value="id"
                  prefix="Kategori: "
                  persistent-hint
                  return-object
                  single-line
                ></v-select>
              </v-col>

            </v-row>

            <div>


              <v-btn
                :loading="uploading"
                class="white--text"
                color="primary"
                @click="uploadVideo"
                :disabled="!ready"
              >
                Last opp
              </v-btn>

              <v-progress-linear :value="progress" v-if="uploading"></v-progress-linear>              

            </div>
          </v-container>
        </v-form>
  </div>
</template>

<script>

import { createVideo } from '@/graphql/mutations'
import * as queries from '@/graphql/queries'
import { API } from 'aws-amplify'
// import uuid from 'uuid';
import slugify from '@/helpers/slugify'
import { Auth, Storage } from 'aws-amplify';
import shortid from 'shortid'

export const listCategories = /* GraphQL */ `
  query listCategorys {
    listCategorys {
      items {
        id
        rank
        name
        slug
        type
      }
    }
  }
`;

export default {
  name: 'VideoCreate',
  data () {
    return {
      kategorivalg: { name: 'ikke-valgt', id: '0' },
      uploading: false,
      file: null,
      title: '',
      slug: '',
      posterTime: 1.5,
      key: true,
      progress: 0,
      videoId: '',
      valid: false,
      categories: null
    }
  },
  created () {
    this.videoId = shortid.generate()
    this.listCategories()
  },
  computed: {
    ready () {
      if (this.uploading) {
        return false
      }
      return this.file && this.title.length > 2 ? true : false
    },
    fileName () {
      if (this.file) {
        const fileName = this.file.name.split('.')[0]
        const ext = this.file.name.split('.').pop()
        const slug = slugify(fileName)
        // console.log('slug', slug)
        // console.log('this.videoId', this.videoId)
        // console.log('ext', ext)
        return slug + '-' + this.videoId + '.' + ext
      }
      return 'ikke-valgt'
    },
  },
  methods: {
    fileSlug () {
      this.slug = slugify(this.title)
    },
    titleSlug () {
      // return this.title.replace(/[&\/\\#, +()$~%.'":*?<>{}]/g, '-').replace(/--/g,'-').toLowerCase()
      return this.title.replace(/[&/\\#, +()$~%.'":*?<>{}]/g, '-').replace(/--/g,'-').toLowerCase()
    },
    async checkUser () {
      Auth.currentAuthenticatedUser()
      .then((user) => {
        window.console.log(`user: ${JSON.stringify(user)}`)
        this.$store.dispatch('setUser', user)
        // console.log("state user", this.$store.state.user)
      })
      .catch( (err) => {
        // this.$store.dispatch('setUser', null)
        window.console.log('user not logged in ?', err)
      })
    },
    // uploadVideo () {
    //   Storage.list('/ffba5videos165301-dev', { level: 'public' })
    //       .then(result => console.log(result))
    //       .catch(err => console.log(err));
    // },
    async uploadVideo () {
      this.uploading = true
      // console.log(this.file)
      const key = this.fileName
      const self = this
      Storage.configure({ level: 'public' })
      //Upload:
      // await this.checkUser()

      Storage.put(key, this.file, { // public by default but need iam!
          // acl: 'public-read',
          cacheControl: '',
          contentDisposition: '',
          // expires: new Date().now() + 60 * 60 * 24 * 7,
          contentType: this.file.type,
          metadata: { key: 'value' },
          progressCallback(progress) {
            // console.log('self', self)
            self.progress = progress.loaded / progress.total * 100
            // console.log(`Uploaded: ${progress.loaded}/${progress.total}, percent: ${self.progress}`)
          },
        // contentDisposition: 'inline'
        },
      ).then( result => {
        console.log('upload result: ', result)
        this.saveVideo ()
      }).catch(err => {
        console.log('Dette gikk galt', err)
        // alert('Dette gikk galt')

        // this.showUploadError = true;
      })
    },
    async listCategories () {
      let result

      try {
        result = await API.graphql({
          query: queries.listCategorys,
          limit: 2000,
          authMode: 'AMAZON_COGNITO_USER_POOLS' // AWS_IAM
        })
        this.categories = result.data.listCategorys.items
        console.log('queries.listCategorys',result)

      } catch (e) {
        console.log(e)
        this.res = e
      }
    },
    async saveVideo () {
      let result
      try {
        result = await await API.graphql({
          query: createVideo,
          authMode: 'AMAZON_COGNITO_USER_POOLS',
          variables: {
            input: {
              title: this.title,
              // id: videoId,
              awsId: '1',
              slug: this.titleSlug(),
              posterTime: this.posterTime,
              published: false,
              fileName: this.fileName,
              categoryId: this.kategorivalg.id,
              type: 'Video'
            }
          }
        })
          console.log('Save result', result)
      } catch (e) {
        console.log('feil', e)
      }
      this.uploading = false
      console.log('result', result.data.createVideo)
      const videoId = result.data.createVideo.id
      console.log('videoId', videoId)
      /* CODE TO STORE / Mutate VIDEO TO DB HERE */
      this.$router.push({name: 'editVideo' , params: { videoId: videoId }}) // Navigate to video edit.
    }
  }
}
</script>
