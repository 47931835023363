<template>
  <div class="scroll-list">
    <CreateVideo />
  </div>
</template>

<script>
import CreateVideo from '@/components/videos/videoCreate'
export default {
  components: {
    CreateVideo
  }
}
</script>

<style>

</style>
